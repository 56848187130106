import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogPostList from "../components/Blog/BlogPostList";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";
export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    blogs: allSanityBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const postNodes =
    data &&
    data.blogs &&
    mapEdgesToNodes(data.blogs)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Privacy Policy | Brierton, Jones & Jones"
        description=""
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-32">
        <div className="absolute top-0 left-0 h-full w-full">
          <StaticImage
            src="../images/News/Hero Desktop.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container relative">
          <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-50">
            
          </div>
          <h1 className="mb-0 text-white">Privacy Policy</h1>
        </div>
      </section>

      <section className="pt-16 pb-20 md:pt-22 md:pb-32">
        <div className="container">
          <p> 

Brierton, Jones & Jones LLP does not collect personally identifying information from visitors to this site unless provided by the visitor or the visitor's browser. Brierton, Jones & Jones LLP will not use information provided by the visitor or the visitor's browser nor will Brierton, Jones & Jones LLP use information provided through your use of this site except to respond to direct inquiries or to measure the number of visitors to and usefulness of this site. Brierton, Jones & Jones LLP does not provide or sell any of this information to third parties. Brierton, Jones & Jones LLP reserves the right to change its policy in this regard at any time without advance notice. Should any new policy go into effect, Brierton, Jones & Jones LLP will post it on this site, and the new policy will apply only to information collected after that point.</p>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
